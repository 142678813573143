<template>
  <div class="container">
    <h2>Relatorio de Nomes</h2>
    <h5>
      Total de Produtos: <strong>{{ report.length }}</strong>
    </h5>
    <hr />
    <!-- Make the b-table -->
    <b-table striped hover :items="report" :fields="fields" :filter="filter" thead-class="text-blue">
      <!-- Add a template for the sku -->
      <template #cell(sku)="data">
        <b-link :href="`/product/${data.item.id_backoffice}`">{{ data.item.sku }}</b-link>
      </template>
      <!-- Add a template for the buttons -->
      <template #cell(buttons)="data">
        <b-button @click="validateNames(data.item.id_backoffice)" variant="success">Validar</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      report: [],
      filter: null,
      fields: [
        {
          key: 'sku',
          label: 'SKU/CNP',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'ean',
          label: 'EAN',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'name_1',
          label: 'Nome 1',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'name_2',
          label: 'Nome 2',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        // Add a new column for the buttons
        {
          key: 'buttons',
          label: 'Validar',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    async getNames() {
      try {
        await this.$store.dispatch('getPendentNames');
        this.report = this.getNamesReport;
        console.log(this.getNamesReport);
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async validateNames(id_backoffice) {
      //Add alert to confirm the validation
      this.$swal({
        title: 'Tem a certeza que deseja validar este nome?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('updatePendentName', {
              id_backoffice: id_backoffice,
            });
            await this.getNames();
            this.$swal({
              title: 'Nome Validado',
              icon: 'success',
            });
          } catch (err) {
            console.log(err);
            alert(err);
          }
        }
      });
    },
  },
  async created() {
    this.getNames();
  },
  computed: {
    ...mapGetters(['getNamesReport']),
  },
};
</script>

<style scoped>
.table {
  text-align: center;
}
.buttons {
  text-align: center;
  margin: 15px 0px 15px 0px;
}
h2 {
  text-align: center;
  margin-top: 5px;
}
h5 {
  text-align: center;
}
.btn {
  margin: 0px 5px 0px 5px;
}
</style>
